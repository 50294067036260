import React, { useState } from 'react';
import TableFull from '../Table';

import * as XLSX from 'xlsx'; // Import xlsx library

// Define TypeScript interfaces for the JSON structure
interface Detail {
  no: number;
  namasubbab: string;
  soal: string;
  total: string;
}

interface SummaryItem {
  namasubbab: string;
  detail: Detail[];
}

interface ResultSummaryOptionalProps {
    columnTable: any[],
    result_summary_optional: SummaryItem[];
}

const ResultSummaryOptional: React.FC<ResultSummaryOptionalProps> = ({ result_summary_optional , columnTable}) => {
  
  const [isHovered, setIsHovered]             = useState(false);
  
  const handleDownload = () => {
    // Prepare data for XLSX conversion
    const data = result_summary_optional.flatMap((item) =>
      item.detail.map((detailItem) => ({
        Subbab: item.namasubbab,
        No: detailItem.no,
        Soal: detailItem.soal,
        Total: detailItem.total,
      }))
    );

    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Summary');

    // Convert workbook to binary XLSX and trigger download
    const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

    function s2ab(s: string) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }

    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'summary.xlsx';
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  };
  const buttonStyle = {
    backgroundColor: isHovered ? 'grey' : 'green',
    color: 'white',
    transition: 'background-color 0.1s', // Add a smooth transition effect
    width: '300px' ,
    padding: '8px 16px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'White',
    },
  };
  return (
    <div>
      {result_summary_optional.map((item, index) => (
        <div className='mt-10'>
            <h4 style={{ fontWeight: 'bold', textAlign: 'center' }}>{item.namasubbab}</h4>
            <TableFull dataTable={item.detail} columnTable={columnTable} />
        </div>
      ))}
      <button onClick={handleDownload} style={buttonStyle} 
          onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} >
          Download XLS
      </button>
    </div>
  );
};

export default ResultSummaryOptional;