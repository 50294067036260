import MainDashboard from "./pages/dashboard/MainDashboard"
import Login from "./pages/auth/Login"
import Quiz from "./pages/question/QuestionPage"
import User from "./pages/dashboard/User"
import Category from "./pages/dashboard/Category"
import Product from "./pages/dashboard/Product"
import HomeContent from "./pages/dashboard/HomeContent"
import AboutUs from "./pages/dashboard/AboutUs"
import ContactUs from "./pages/dashboard/ContactUs"
import Signup from "./pages/auth/SignUp"
import Admin_deptmanage from "./pages/dashboard/Admin_deptmanage"
import Admin_usermanage from "./pages/dashboard/Admin_usermanage"
import Report_survey from "./pages/dashboard/Report_survey"
import Survey_1_Mastersurvey from "./pages/dashboard/Survey_1_Mastersurvey"
import Survey_2_Subbab from "./pages/dashboard/Survey_2_Subbab"
import Survey_3_Soal from "./pages/dashboard/Survey_3_Soal"
import Administrator_managecode from "./pages/dashboard/Administrator_managecode"


export const AdminRoutes = [
    {
        name_var: 'Dashboard',
        role: 0,
        url_var: '',
        icon_var: 'ri-home-line'
    },
    {
        name_var: 'Admin',
        role: 2,
        url_var: 'peserta-content',
        icon_var: 'ri-team-line',
        children: [
            {
                name_var: 'Departement Management',
                url_var: 'admin_deptmanage',
                icon_var: 'ri-chat-poll-line'
            },
            {
                name_var: 'User Management',
                url_var: 'admin_usermanage',
                icon_var: 'ri-chat-poll-line'
            },
        ]
    },
    {
        name_var: 'Report',
        role: 2,
        url_var: 'report-content',
        icon_var: 'ri-file-chart-fill',
        children: [
            {
                name_var: 'Result Survey',
                url_var: 'report_survey',
                icon_var: 'ri-chat-poll-line'
            },
        ]
    },
    {
        name_var: 'Survey Management',
        role: 2,
        url_var: 'Survey-content',
        icon_var: 'ri-task-line',
        children: [
            {
                name_var: 'Master Survey',
                url_var: 'survey_mastersurvey',
                icon_var: 'ri-chat-poll-line'
            },
            {
                name_var: 'SubSurvey',
                url_var: 'survey_subbab',
                icon_var: 'ri-chat-poll-line'
            },
            {
                name_var: 'Soal Survey',
                url_var: 'survey_soal',
                icon_var: 'ri-chat-poll-line'
            }
        ]
    },
    {
        name_var: 'Admininstrator',
        role: 99,
        url_var: 'admin-content',
        icon_var: 'ri-admin-fill',
        children: [
            {
                name_var: 'Manage Web Code',
                url_var: 'administrator_code',
                icon_var: 'ri-chat-poll-line'
            }
        ]
    }
]



export const dashboardRoutes = [
    {
        path: '',
        component: <MainDashboard />
    },
    {
        path: 'peserta-content',
        component: <MainDashboard />
    },
    {
        path: 'admin-content',
        component: <MainDashboard />
    },
    {
        path: 'route-content',
        component: <MainDashboard />
    },
    {
        path: 'admin_deptmanage',
        component: <Admin_deptmanage />
    },
    {
        path: 'admin_usermanage',
        component: <Admin_usermanage />
    },
    {
        path: 'report_survey',
        component: <Report_survey />
    },
    {
        path: 'survey_mastersurvey',
        component: <Survey_1_Mastersurvey />
    },
    {
        path: 'survey_subbab',
        component: <Survey_2_Subbab />
    },
    {
        path: 'survey_soal',
        component: <Survey_3_Soal/>
    },
    {
        path: 'administrator_code',
        component: <Administrator_managecode/>
    },
    // {
    //     path : "home-content",
    //     component: <HomeContent />
    // },
    // {
    //     path : "product-category",
    //     component: <Category />
    // },
    // {
    //     path : "about-us",
    //     component: <AboutUs />
    // },
    // {
    //     path : "contact-us",
    //     component: <ContactUs />
    // },
    // {
    //     path : "product-catalog",
    //     component: <Product />
    // },
    // {
    //     path : "user-management",
    //     component: <User />
    // },
    // USER ROUTE
]


export const AuthRoutes = [
    {
        path: 'sign-in',
        component: <Login />
    },
    {
        path: 'sign-up',
        component: <Signup />
    }
    
]

export const SurveyRoutes = [
    {
        path: 'quiz',
        component: <Quiz />
    }
]