import React, { useEffect , useState } from 'react';
import moment, { Moment } from 'moment';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SubmitFunction, useNavigate } from 'react-router-dom';
import ErrorField from '../../components/ErrorField';
import appLogo from '../../assets/img/applogo.png'
import { SubmitHandler, useForm } from 'react-hook-form';
import { list_dep, Registerapi, RegisterPayload } from '../../api/common-api';
import { Get_list_departement, Code_check, clucode_check } from '../../api/common-api';

import Helper from "../../utils/Helper";

import { toast } from 'react-toastify';

type Props = {};

const SignUp: React.FC<Props> = () => 
{
    const [selectedDate_str, setSelectedDate_str] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date());
    
    const handleDateChanges = (value: string | Moment) => {
        if (typeof value === 'string') {
            setSelectedDate(new Date(value));
        } else {
            setSelectedDate(value.toDate());
            setSelectedDate_str(value.format('YYYY-MM-DD'));
        }
        console.log("Date [Str] "+ selectedDate_str + " [Date] " + selectedDate)
    };

    const [web_code, set_web_code]               = useState('');
    const [modal_blok, showModal_blok]          = useState(false);
    const [modal_form, showModal_form]      = useState(false);
    const [modal_btnback_blok, set_modal_btnback_blok]      = useState(false);

    const [isHovered_check, setIsHovered_check] = useState(false);
    const [isHovered, setIsHovered]             = useState(false);
    const [isHovered_green, setIsHovered_green]             = useState(false);
    
    const [payload_clucheck, set_payload_clucheck]         = useState<clucode_check>();
    // const [listdep, set_listdep]         = useState<clucode_check[]>();
    const [listdep, setListdep] = useState<list_dep[]>([]);

    const navigate = useNavigate()

    const buttonStyle_green = {
        backgroundColor: isHovered_green ? '#4ADE80' : '#2EC862',
        color: 'white',
        transition: 'background-color 0.1s', // Add a smooth transition effect
        
        height: '40px', // Set the height of the button
        width: '300px' ,
        padding: '8px 16px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        ':hover': {
          backgroundColor: 'White',
        },
      };
      const buttonStyle = {
            backgroundColor: isHovered ? 'grey' : 'red',
            color: 'white',
            transition: 'background-color 0.1s', // Add a smooth transition effect
            width: '300px' ,
            padding: '8px 16px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            ':hover': {
              backgroundColor: 'White',
            },
          };
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<RegisterPayload>({ criteriaMode: "all" });


    const fetchDepartments = async () => {
        
        const res = await Get_list_departement()

        console.log('Fetch Get_list_departement :', res)
        if(res.data){
            if(res.data.code === 0){
                setListdep(res.data.data)
            } else if(res.data.code === 99){
                navigate('/auth')
            }else{
                toast.error(res.data.message)
            }
        }else{
            toast.error(res.message)
        }
      };

    useEffect(() => {
        localStorage.clear();
        fetchDepartments();
    }, [])

    const back_btn = () =>{
        navigate("/auth/sign-in");
    }
    
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        set_web_code(event.target.value)
        set_payload_clucheck({
            ...payload_clucheck,
            "code_web": event.target.value
        });
    };
    const Check_code = async () => {
        console.log("> onCheck :", payload_clucheck?.code_web ," | ", modal_blok);
        
        showModal_blok(false)
        console.log("> Code_check :",payload_clucheck )
        const res = await Code_check(payload_clucheck as clucode_check )
        console.log("< Code_check :", res)
        if(res.data)
        {
            if(res.data.code == 0)
            {
                console.log("DATA :", res.data)
                showModal_form(true);
                // if(res.data.data.rowCount > 0)
                // {
                //     console.log("DATA :", res.data)
                //     console.log("DATA :", res.data.data.rows)
                //     setdata_Blok(res.data.data.rows)
                //     // set_modal_modal_validrole(true)
                //     showModal_blok(true)
                //     set_modal_btnback_blok(true)
                //     set_modeform_global(true)
                //     set_statuskeluarga("Kepala Keluarga")
                // }
                // else
                // {
                //     toast.error('['+res.request.status+'] Data Blok Tidak ditemukan : '+res.data.data.rowCount)
                // }
            }
            // else if(res.data.code == 1)
            // {
            //     console.log("DATA :", res.data.data)
            //     console.log("Asal Setter :" + res.data.data.clu_code + " | " + res.data.data.blok_name + " | " + res.data.data.number_home + " | " + res.data.data.data_rt+ " | " + res.data.data.data_rw+ " | " + res.data.data.role_id )
            
            //     console.log("Setter :" + data_rt + " | " + data_rw + " | " + clu_code + " | " + blok_name+ " | " + number_home )
                
            //     set_clucode(res.data.data.clu_code)
            //     set_blok_name(res.data.data.blok_name)
            //     set_number_home(res.data.data.number_home)
            //     set_data_rt(res.data.data.data_rt)
            //     set_data_rw(res.data.data.data_rw)
            //     set_data_roleid(res.data.data.role_id)
            //     set_statustinggal(res.data.data.status_tinggal)
                
            //     set_modeform_global(false)
            //     showModal_form(true)
            //     console.log("Setter :" + data_rt + " | " + data_rw + " | " + clu_code + " | " + blok_name+ " | " + number_home )
            // }
            else
            {
                toast.error('['+res.request.status+']Failed : '+res.data.message ,res.data.message)
            }
        }else{
            toast.error('Connection timeout..')
        }
        // try {
        //     const res = await Registerapi(formData as RegisterPayload );
        //     console.log("< onCheck_clu : ", res.data);
        // } catch (error) {
        //     console.error("Error:", error);
        //     toast.error('An error occurred. Please try again later.');
        // }
    };
    const onSubmitForm: SubmitHandler<RegisterPayload> = async (data) => {
        // data.identity_tanggallahir = format(selectedDate, 'yyyy-MM-dd');
        // data.image_photo = "" ;

        // data.rt_account_level = "1" ;
        // data.rt_sub_level_role = "1" ;
        // data.rw_account_level = "1" ;
        // data.rw_sub_level_role = "1" ;
        // if(modeform_global)
        // {
        //     const min = 10000; // Minimum 5-digit number
        //     const max = 99999; // Maximum 5-digit number
        //     const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
        //     data.code_generated =  ""+ data.clu_code + data.blok_name + data.number_home + data.username +"-"+randomNumber;
        //     data.kepala_kk = "1" ;
        //     data.status_user = "1" ;
        //     data.status_keluarga = statuskeluarga;
            
        // }else{
        //     console.log(data_roleid);
        //     data.role_id = data_roleid;
        //     data.clu_code = ""+clu_code;
        //     data.code_generated =  ""+clu_code_generated;//data.clu_code;
        //     data.blok_name = ""+blok_name;
        //     data.number_home = ""+number_home;
        //     data.data_rt = ""+data_rt;
        //     data.data_rw = ""+data_rw;
        //     data.kepala_kk = "0" ;
        //     data.status_user = "2" ;
        //     data.status_tinggal = statustinggal;
        // }

        
        // data.identity_tempatlahir = "" + data.identity_tempatlahir.toUpperCase() ;
        // data.identity_pekerjaan = "" + data.identity_pekerjaan.toUpperCase() ;
        // data.identity_kewarganegaraan = "" + data.identity_kewarganegaraan.toUpperCase() ;

        console.log("> Register :",data )
        const res = await Registerapi(data as RegisterPayload )
        console.log("< Register :", res)
        if(res.data){
            if(res.data.code === 0){
                const data = res.data.data
                const dataUser = {
                    id: data.role_id,
                    username: data.username,
                    fullname: data.fullname,
                    email: data.email,
                    role_id: data.role_id,
                    last_login_on: data.last_login_on
                }

                localStorage.setItem('dataUser', JSON.stringify(dataUser))
                localStorage.setItem('authToken', data.token)
                toast.done(data.message)
                navigate('/auth/sign-in', {replace: true})
            }else{
                toast.error(res.data.message)
            }
        }else{
            toast.error('Connection timeout..')
        }
    };
    return (
        <>
            <div className="h-full flex flex-col justify-center items-center space-y-8">
               <LazyLoadImage effect='blur' src={appLogo} className='w-30 h-20 mx-auto' />
                <div className="ml-4">
                    <h1 className="text-2xl md:text-3xl font-extrabold">Sign Up to your account</h1>
                    <p className="mt-2">Registration Form</p>
                </div>
                {!modal_form && ( 
                    <div className="flex flex-col items-center"> {/* Menggunakan items-center untuk membuat rata tengah */}
                        <div className=" md:flex md:space-x-10 space-y-5 md:space-y-0 space-x-4 items-center justify-center">
                            <div >
                                <label className="font-semibold mb-1 ml-5 mr-5" style={{ width: '100px' }} htmlFor="code_web">CODE</label>
                                <input id="code_web" type="text" autoComplete="code_web"
                                    className="text-input"
                                    {...register("code_web", {
                                        required: "code_web is required."
                                    })}
                                    disabled={modal_blok}
                                    onChange={(event) => {
                                        handleChange(event);
                                    }}
                                    style={{ width: '300px' }}
                                />
                            </div>
                        </div>
                        <ErrorField errors={errors} name='code_web' />
                        
                        {!modal_blok && ( 
                            <div className="flex flex-col items-center justify-center">
                                <button className=" mt-4" onClick={Check_code} style={buttonStyle_green} 
                                    onMouseEnter={() => setIsHovered_check(true)} onMouseLeave={() => setIsHovered_check(false)} >
                                    Check Code
                                </button>
                                <button className=" mt-4" onClick={back_btn} style={buttonStyle}
                                    onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} >
                                    Back To Login
                                </button>
                            </div>
                        )}
                    </div>
                )}
                
                <form onSubmit={handleSubmit(onSubmitForm)} className="space-y-7 px-3 md:px-0 w-11/12 md:w-7/12">
                    {modal_form && ( 
                        <div>
                            <div className="flex-1">
                                <label className="font-semibold" htmlFor="username">Fullname</label>
                                <input id="fullname" type="text" autoComplete="username" className="text-input"
                                    {...register("fullname", {
                                    required: "fullname is required."
                                    })} />
                                <ErrorField errors={errors} name='fullname' />
                            </div>
                            <div className="flex-1">
                                <label className="font-semibold" htmlFor="username">Username</label>
                                <input id="username" type="text" autoComplete="username" className="text-input"
                                    {...register("username", {
                                    required: "Username is required."
                                    })} />
                                <ErrorField errors={errors} name='username' />
                            </div>
                            <div className="flex-1">
                                <label className="font-semibold" htmlFor="password">Password</label>
                                <input id="password" type="password" autoComplete="current-password" className="text-input"
                                    {...register("password", {
                                    required: "Password is required.",
                                    minLength: { value: 5, message: "Password must exceed 4 characters." }
                                    })} />
                                <ErrorField errors={errors} name='password' />
                            </div>
                            <div>
                                <label className="font-semibold">Departemen</label>
                                <select id="add_rt" className="input-form SelectedRole" 
                                    {...register('dept_id', {required: 'dept_id is required'})}>
                                    <option value=''>- Pilih Departemen -</option>
                                    {/* {listdep?.map((item, key) =>
                                        <option key={key} value={item.dept_id}>{item.dept_desc_var.toUpperCase()}</option>
                                    )} */}
                                    
                                     {listdep.map((item, key) => (
                                        <option key={key} value={String(item.dept_id)}>
                                            {item.dept_desc_var.toUpperCase()}
                                        </option>
                                        ))}
                                </select>
                                <ErrorField errors={errors} name='add_rt' />
                            </div>
                            <div className="flex-1">
                                <label className="font-semibold" htmlFor="username">Jabatan Departemen</label>
                                <input id="status_dept_var" type="text" autoComplete="username" className="text-input"
                                    {...register("status_dept_var", {
                                    required: "status_dept_var is required."
                                    })} />
                                <ErrorField errors={errors} name='status_dept_var' />
                            </div>
                            <div className="flex flex-col items-center justify-center">
                                <button type='submit' className=" justify-center btn-submit btn-margin" style={{ marginTop:'10px', marginBottom:'10px', width: '300px' }}>
                                    Regist
                                </button>
                                
                                <button className="justify-center btn-submit" onClick={back_btn} style={buttonStyle} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                                    Back To Login
                                </button>
                            </div>
                        </div>
                    )}
                </form>

                <h1>{Helper.getBydev()}</h1>
                <h1>{Helper.getVersion()}</h1>
            </div>
        </>
    );
}

export default SignUp;