import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthRoutes } from '../routes';
import NotFound from '../pages/dashboard/NotFound';
import { ToastContainer } from 'react-toastify';

const BG = [
    require('../assets/img/bg-1.jpg'),
    require('../assets/img/bg-2.jpg'),
    require('../assets/img/bg-3.jpg'),
]


// MAIN ROUTE
const getRoutes = () => {
    return AuthRoutes.map((data, key) => {
        return <Route path={data.path} element={data.component} key={key} />
	})
}

type Props = {};

const Auth: React.FC<Props> = () => {
	const [bgImage, setBgImage] = useState(BG[0])

    useEffect(() => {
        // setBgImage(BG[Math.floor(Math.random() * BG.length)])
    }, [])

    return (
        <>
            <div className="bg-[#eee] min-h-screen flex justify-center items-center font-poppins">
				<div className="relative w-[1366px] h-[768px]">
                    <Routes>
                        {getRoutes()}

                        <Route path='*' element={<NotFound />} /> 
                        <Route path='/' element={<Navigate replace to='/auth/sign-in' />} />

                    </Routes>
				</div>
			</div>
            

            <ToastContainer
                position='bottom-left'
                theme='dark'
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover 
            />
        </>
    );
}

export default Auth;