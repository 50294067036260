import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import ErrorField from '../../components/ErrorField';
import appLogo from '../../assets/img/applogo.png'
import { SubmitHandler, useForm } from 'react-hook-form';
import { loginAPI, LoginPayload } from '../../api/common-api';
import { toast } from 'react-toastify';
import Helper from "../../utils/Helper";

type Props = {};

const Login: React.FC<Props> = () => 
{
    const [isHovered, setIsHovered]             = useState(false);
    const calculateWidth = () => {
        const minWidth = 100; // Minimum width in pixels
        const charWidth = 10; // Average width of a character in pixels
        return `${minWidth + 15 * charWidth}px`;
      };
    
      const buttonStyle = {
        backgroundColor: isHovered ? 'grey' : 'red',
        color: 'white',
        transition: 'background-color 0.1s', // Add a smooth transition effect
        width: '300px' ,
        padding: '8px 16px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        ':hover': {
          backgroundColor: 'White',
        },
      };
      const buttonStyle_green = {
        width: '300px' ,
        padding: '8px 16px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        ':hover': {
          backgroundColor: 'White',
        },
      };

    const navigate = useNavigate()

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<LoginPayload>({ criteriaMode: "all" });

    useEffect(() => {
        localStorage.clear()
    }, [])

    const onSubmitForm: SubmitHandler<LoginPayload> = async (data) => {
        const res = await loginAPI(data as LoginPayload )
        console.log("Login :", res)
        if(res.data)
        {
            if(res.data.code == 0){
                const data = res.data.data
                console.log(" 1Login :" , data)
                const dataUser = {
                    id: data.role_id,
                    username: data.username,
                    code_web: data.code_web,
                    fullname: data.fullname,
                    email: data.email,
                    role_id: Number( data.role_id),
                    last_login_on: data.last_login_on,
                    code_quiz: data.code_quiz
                }

                localStorage.setItem('dataUser', JSON.stringify(dataUser))
                localStorage.setItem('authToken', data.token)
                if(data.code_quiz != null)
                {
                    if(data.code_quiz.length > 3 && data.code_quiz != 'null')
                    {
                        console.log(" Goto Quiz")
                        navigate('/quiz/quiz/');
                    }
                    else
                    {
                        console.log(" Goto dashboard")
                        navigate('/dashboard', {replace: true})
                    }
                }
                else
                {
                    console.log(" Goto dashboard")
                    navigate('/dashboard', {replace: true})
                }
            }else{
                console.log(" 3Login : ", res.data.message)
                toast.error(res.data.message)
                toast.error('['+res.data.code+']Failed : '+res.data.message ,res.data.message)
            }
        }else{
            console.log(" 2Login :")
            toast.error('Connection timeout..')
        }

        // const dataUser = {
        //     id: 1,
        //     username: data.username,
        //     fullname: data.username,
        //     email: `${data.username}@mail.copm`,
        //     role_id: 1,
        //     last_login_on: moment().format('yyyy-MM-DD HH:mm:ss')
        // }
        // localStorage.setItem('dataUser', JSON.stringify(dataUser))
        // localStorage.setItem('authToken', '[its gonna be jwt token from login api]')
    }

    const signups = () =>{
        navigate("/auth/sign-up");
    }

    return (
        <>
            <div className="h-full flex flex-col justify-center items-center space-y-8">
                <LazyLoadImage effect='blur' src={appLogo} className='w-30 h-20 mx-auto' />
                <div className='text-center'>
                    <h1 className="text-2xl md:text-3xl font-extrabold">Sign In To Account</h1>
                    <p className="mt-5">Web Survey Fadila Maulia S</p>
                </div>

                <form onSubmit={handleSubmit(onSubmitForm)} className="space-y-7 px-3 md:px-0 w-11/12 md:w-7/12">
                    <div>
                        <label className="font-semibold" htmlFor="username">Username</label>
                        <input id="username" type="text" autoComplete="username" className="text-input"
                            {...register("username", {
                                required: "username is required."
                            })} />
                        <ErrorField errors={errors} name='username' />
                    </div>
                    <div>
                        <label className="font-semibold" htmlFor="password">Password</label>
                        <input id="password" type="password" autoComplete="current-password" className="text-input"
                            {...register("password", {
                                required: "Password is required.",
                                minLength: { value: 5, message: "Password must exceed 4 characters." }
                            })} />
                        <ErrorField errors={errors} name='password' />
                    </div>


                    <div className="flex flex-col items-center justify-center">
                        <button type='submit' className="btn-submit mb-5" style={buttonStyle_green} >
                            Sign In
                        </button>
                        <button onClick={signups} style={buttonStyle} 
                            onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} >
                            Sign Up Account
                        </button>
                    </div>
                </form>

                <h1>{Helper.getBydev()}</h1>
                <h1>{Helper.getVersion()}</h1>
            </div>
        </>
    );
}

export default Login;