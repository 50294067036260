import jwtDecode from "jwt-decode"
import { AdminRoutes } from "../routes"

class Helper {

    static getTitleBar(pathname: string){
        if(pathname === "/dashboard") return "Dashboard"
        else return AdminRoutes.find(data => pathname==='/dashboard/' + data.url_var)?.name_var
    }
    
    static getVersion(){
        return "© WebSurvey Fdl v1.0.4"
    }

    static getBydev(){
        return "Design by YPRO"
    }

    static getRoleName(roleID: number){
        switch(roleID){
            case 1:
                return "Peserta"
            case 98:
                return "Admin"
            case 99:
                return "Superadmin"
            default:
                return "Unknow"
        }
    }

    static expiredSession(token: string){
		if(token){
            return true
		}else{
            // const decode = jwtDecode(token) as any
            // const currentTime = Date.now() / 1000;
            
            // if(decode.exp < currentTime){
            //     return true
            // }else{
            //     return false
            // }
            const decode = jwtDecode(token) as { exp?: number };

            if (decode.exp) {
                const currentTime = Date.now() / 1000;
                return decode.exp < currentTime;
            }
		}
    }
}

export default Helper