import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createNewUser, deleteUser, dept_getAll, getAllUser, updateUser, UserPayload ,dept_list, role_list, role_getAll, UserEditPayload, UserEditHITPayload, UserRemoveHITPayload} from '../../api/user-api';
import UserForm from '../../components/modals/UserForm';
import SearchField from '../../components/SearchField';
import TableFull from '../../components/Table';

type Props = {};

const Admin_usermanage: React.FC<Props> = () => {
    const [loader, showLoader] = useState(false)
    const [modalUser, showModalUser] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const [dataDept, setDept] = useState<dept_list[]>([])
    const [dataRole, setRole] = useState<role_list[]>([])
    const [dataUser, setDataUser] = useState([])
    const [filterData, setFilterData] = useState([])
    const [selectedUser, setSelectedUser] = useState<UserPayload>({})
    const [payloadEditUser, setpayloadEditUser] = useState<UserEditHITPayload>({})
    const [payloadRemoveUser, setpayloadRemoveUser] = useState<UserRemoveHITPayload>({})
    
    const userLocal = localStorage.getItem('dataUser')
    const user = userLocal? JSON.parse(userLocal || '') : null

    const navigate = useNavigate()

    const fetchUser = useCallback( async () => {
        showLoader(true)
        const res = await getAllUser()

        showLoader(false)
        // console.log('Fetch User :', res)
        if(res.data){
            if(res.data.code === 0){
                setDataUser(res.data.data)
                setFilterData(res.data.data)
            } else if(res.data.code === 99){
                navigate('/auth')
            }else{
                toast.error(res.data.message)
            }
        }else{
            fetchUser()
        }
        
        showLoader(true)

        const res_dep = await dept_getAll()

        showLoader(false)
        // console.log('Fetch Dept :', res_dep)
        if(res_dep.data)
        {
            if(res_dep.data.code === 0){
                setDept(res_dep.data.data)
            }else{
                toast.error(res_dep.data.message)
            }
        }
        showLoader(true)

        const res_role = await role_getAll()

        showLoader(false)
        // console.log('Fetch Role :', res_role)
        if(res_role.data)
        {
            if(res_role.data.code === 0){
                setRole(res_role.data.data)
            }else{
                toast.error(res_role.data.message)
            }
        }
    }, [navigate])

    useEffect(() => {
        fetchUser()
    }, [fetchUser])


    const handleEditData =  (selectedData: UserPayload) => {
        setSelectedUser(selectedData)
        setIsUpdate(true)
        showModalUser(true)
    }

    const handleReceiveDataForm = async (data: UserEditPayload) => {
        showLoader(true)
        // console.log('updateUser ',data)
        payloadEditUser.username = user.username;
        payloadEditUser.target_username = data.username;
        payloadEditUser.target_fullname = data.fullname;
        payloadEditUser.target_password = data.password;
        payloadEditUser.target_role_id = String(data.role_id);
        payloadEditUser.target_status_dept_var = data.status_dept_var;
        payloadEditUser.target_dept_id = String(data.dept_id);
        let res = null
        res = await updateUser(payloadEditUser)
        // console.log('Update User :', res)
        
        if(res.data){
            if(res.data.code === 0){
                toast.success('Success')
                fetchUser()
                resetForm()
            }else{
                toast.error(res.data.message)
                showLoader(false)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }

    const handleDeleteItem = async (data: UserPayload) => {
        const confirmed = window.confirm("Are you sure you want to delete this item?");
        if (!confirmed) return;
    
        try {
            payloadRemoveUser.username = user.username;
            payloadRemoveUser.target_username = data.username;
            const res = await deleteUser(payloadRemoveUser);
    
            // console.log("DELETE USER :", res);
            if (res.data) {
                if (res.data.code === 0) {
                    toast.success('Success');
                    fetchUser();
                } else {
                    toast.error(res.data.message);
                }
            } else {
                alert(`${res.config?.url} ${res.message}`);
            }
        } catch (error) {
            console.error("Error deleting user:", error);
            toast.error("An error occurred while deleting the user.");
        }
    }

    const resetForm = () => {
        setSelectedUser({})
        setIsUpdate(false)
        showModalUser(false)
        showLoader(false)
    }

    const columns = [
        {
            Header: 'no',
            Footer: 'no',
            accessor: 'no',
        },
        {
            Header: 'username',
            Footer: 'username',
            accessor: 'username'
        },
        {
            Header: () => <span className='p-4'>Role</span>,
            Footer: 'role_desc_var',
            accessor: 'role_desc_var',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: 'dept_desc_var',
            Footer: 'dept_desc_var',
            accessor: 'dept_desc_var'
        },
        {
            Header: 'status_dept_var',
            Footer: 'status_dept_var',
            accessor: 'status_dept_var'
        },
        {
            Header: 'Last Login',
            Footer: 'Last Login',
            accessor: 'last_login_on',
            Cell: ({value}: any) => <p>{value? moment(value).format('yyyy-MM-DD HH:mm:ss') : ''}</p>
        },
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({row}: any) => {
                const data = row.original
                if(parseInt(user?.role_id) >= 2){
                    return (
                        <div className='text-xl scale-110 space-x-1 2xl:space-x-1.5'>
                            <i className="ri-edit-box-line text-primary hover:text-green-400 cursor-pointer" onClick={() =>handleEditData(data)}></i>
                            <i className="ri-delete-bin-2-line text-red-500 hover:text-red-400 cursor-pointer" onClick={()=>handleDeleteItem(data)}></i>
                        </div>
                    )
                }
                return <></>
            }
        }
    ]

    const handleSearch = (event: any) => {
        event.preventDefault()

        const newData = [...dataUser]
        if(event.target.value){
            const filtered = newData.filter((item: any) => {
                return (
                    item.fullname?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.username?.toLowerCase().includes(event.target.value.toLowerCase()) || 
                    item.email?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.role_name?.toLowerCase().includes(event.target.value.toLowerCase())
                )
            });

            setFilterData(filtered)
        }else{
            setFilterData(dataUser)
        }
    }

    return (
        <>
            <div className='flex item-center flex-col p-6'>

                <div className='w-full bg-white rounded-lg shadow-xl p-6'>
                    <div className='mb-5'>
                        <h1 className='font-bold'>User Management</h1>
                    </div>

                    {/* TABLE */}
                    <div className='flex justify-between md:items-center flex-col md:flex-row space-y-2 md:space-y-0 mb-3'>
                        {/* <button className='btn-primary' onClick={()=>showModalUser(true)}>Create New</button> */}
                        <SearchField placeholder='Search user...' onChange={handleSearch} />
                    </div>
                    <TableFull dataTable={filterData} columnTable={columns} />

                </div>
            </div>
            {modalUser && <UserForm data={selectedUser} list_dep={dataDept} list_role={dataRole}  onClose={resetForm} onSubmit={handleReceiveDataForm} />}
        </>
    );
}

export default Admin_usermanage;