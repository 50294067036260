import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createNewUser, deleteUser, dept_add, dept_delete, dept_getAll, updateUser, UserPayload } from '../../api/user-api';
import DeptForm from '../../components/modals/DeptForm';
import SearchField from '../../components/SearchField';
import TableFull from '../../components/Table';

type Props = {};

const Admin_deptmanage: React.FC<Props> = () => {
    const [loader, showLoader] = useState(false)
    const [modalUser, showModalUser] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const [dataDept, setDatDept] = useState([])
    const [filterData, setFilterData] = useState([])
    const [selectedUser, setSelectedUser] = useState<UserPayload>({})
    
    const [payload_dept_delete, setdept_delete] = useState<dept_delete>({})
    
    const userLocal = localStorage.getItem('dataUser')
    const user = userLocal? JSON.parse(userLocal || '') : null

    const navigate = useNavigate()

    const fetchUser = useCallback( async () => {
        showLoader(true)
        const res = await dept_getAll()

        showLoader(false)
        // console.log('Fetch Dept :', res)
        if(res.data){
            if(res.data.code === 0){
                setDatDept(res.data.data)
                setFilterData(res.data.data)
            } else if(res.data.code === 99){
                navigate('/auth')
            }else{
                toast.error(res.data.message)
            }
        }else{
            fetchUser()
        }
    }, [navigate])

    useEffect(() => {
        fetchUser()
    }, [fetchUser])


    const handleEditData =  (selectedData: UserPayload) => {
        setSelectedUser(selectedData)
        setIsUpdate(true)
        showModalUser(true)
    }

    const handleReceiveDataForm = async (data: dept_delete) => {
        showLoader(true)
        
        let res = null
        data.username = user.username;
        res = await dept_add(data)

        // console.log('Create Dept :', res)
        
        if(res.data){
            if(res.data.code === 0){
                toast.success('Success')
                fetchUser()
                resetForm()
            }else{
                toast.error(res.data.message)
                showLoader(false)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }

    const handleDeleteItem = async (data: dept_delete) => {
        const confirmed = window.confirm("Are you sure you want to delete this item?");
        if (!confirmed) return;
    
        try {
            payload_dept_delete.username = user.username;
            payload_dept_delete.dept_id = data.dept_id;
            payload_dept_delete.dept_desc_var = data.dept_desc_var;
            const res = await dept_delete(payload_dept_delete);
    
            // console.log("DELETE USER :", res);
            if (res.data) {
                if (res.data.code === 0) {
                    toast.success('Success');
                    fetchUser();
                } else {
                    toast.error(res.data.message);
                }
            } else {
                alert(`${res.config?.url} ${res.message}`);
            }
        } catch (error) {
            console.error("Error deleting user:", error);
            toast.error("An error occurred while deleting the user.");
        }
    };
    
    const resetForm = () => {
        setSelectedUser({})
        setIsUpdate(false)
        showModalUser(false)
        showLoader(false)
    }

    const columns = [
        {
            Header: 'dept_id',
            Footer: 'dept_id',
            accessor: 'dept_id',
        },
        {
            Header: 'dept_desc_var',
            Footer: 'dept_desc_var',
            accessor: 'dept_desc_var'
        },
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({row}: any) => {
                const data = row.original
                if(parseInt(user?.role_id) === 99){
                    return (
                        <div className='text-xl scale-110 space-x-1 2xl:space-x-1.5'>
                            {/* <i className="ri-edit-box-line text-primary hover:text-green-400 cursor-pointer" onClick={() =>handleEditData(data)}></i> */}
                            <i className="ri-delete-bin-2-line text-red-500 hover:text-red-400 cursor-pointer" onClick={()=>handleDeleteItem(data)}></i>
                        </div>
                    )
                }

                return <></>
            }
        }
    ]

    const handleSearch = (event: any) => {
        event.preventDefault()

        const newData = [...dataDept]
        if(event.target.value){
            const filtered = newData.filter((item: any) => {
                return (
                    item.fullname?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.username?.toLowerCase().includes(event.target.value.toLowerCase()) || 
                    item.email?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.role_name?.toLowerCase().includes(event.target.value.toLowerCase())
                )
            });

            setFilterData(filtered)
        }else{
            setFilterData(dataDept)
        }
    }

    return (
        <>
            <div className='flex item-center flex-col p-6'>

                <div className='w-full bg-white rounded-lg shadow-xl p-6'>
                    <div className='mb-5'>
                        <h1 className='font-bold'>Departement List</h1>
                    </div>

                    {/* TABLE */}
                    <div className='flex justify-between md:items-center flex-col md:flex-row space-y-2 md:space-y-0 mb-3'>
                        <button className='btn-primary' onClick={()=>showModalUser(true)}>Create New</button>
                        <SearchField placeholder='Search user...' onChange={handleSearch} />
                    </div>
                    <TableFull dataTable={filterData} columnTable={columns} />

                </div>
            </div>
            {modalUser && <DeptForm data={selectedUser} onClose={resetForm} onSubmit={handleReceiveDataForm} />}
        </>
    );
}

export default Admin_deptmanage;