import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { code_add, code_delete, code_getAll, code_payload, createNewUser, deleteUser, getAllUser, updateUser, UserPayload } from '../../api/user-api';
import UserForm from '../../components/modals/UserForm';
import SearchField from '../../components/SearchField';
import TableFull from '../../components/Table';
import CodeAddform from '../../components/modals/CodeAddform';

type Props = {};

const Administrator_managecode: React.FC<Props> = () => {
    const [loader, showLoader] = useState(false)
    const [modalUser, showModalUser] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const [dataUser, setDataUser] = useState([])
    const [filterData, setFilterData] = useState([])
    const [code_payload, setcode_payload] = useState<code_payload>({})
    const [selectedUser, setSelectedUser] = useState<UserPayload>({})
    const userLocal = localStorage.getItem('dataUser')
    const user = userLocal? JSON.parse(userLocal || '') : null

    const navigate = useNavigate()

    const fetchUser = useCallback( async () => {
        showLoader(true)
        const res = await code_getAll()
        showLoader(false)

        console.log('Fetch Code :', res)
        if(res.data){
            if(res.data.code === 0){
                setDataUser(res.data.data)
                setFilterData(res.data.data)
            } else if(res.data.code === 99){
                navigate('/auth')
            }else{
                toast.error(res.data.message)
            }
        }else{
            fetchUser()
        }
    }, [navigate])

    useEffect(() => {
        fetchUser()
    }, [fetchUser])


    const handleEditData =  (selectedData: UserPayload) => {
        setSelectedUser(selectedData)
        setIsUpdate(true)
        showModalUser(true)
    }

    const handleReceiveDataForm = async (data: code_payload) => {
        showLoader(true)
        try {
            code_payload.username = user.username;
            code_payload.code_web = data.code_web;
            const res = await code_add(code_payload);
    
            if (res.data) {
                if (res.data.code === 0) {
                    toast.success('Success');
                    fetchUser();
                } else {
                    toast.error(res.data.message);
                }
            } else {
                alert(`${res.config?.url} ${res.message}`);
            }
            showModalUser(false)
        } catch (error) {
            console.error("Error deleting user:", error);
            toast.error("An error occurred while deleting the user.");
        }
        showLoader(false)
    }

    const handleDeleteItem = async (data: code_payload) => {
        const confirmed = window.confirm("Are you sure you want to delete this item?");
        if (!confirmed) return;
    
        try {
            code_payload.username = user.username;
            code_payload.code_web = data.code_web;
            const res = await code_delete(code_payload);
    
            if (res.data) {
                if (res.data.code === 0) {
                    toast.success('Success');
                    fetchUser();
                } else {
                    toast.error(res.data.message);
                }
            } else {
                alert(`${res.config?.url} ${res.message}`);
            }
        } catch (error) {
            console.error("Error deleting user:", error);
            toast.error("An error occurred while deleting the user.");
        }
    }

    const resetForm = () => {
        setSelectedUser({})
        setIsUpdate(false)
        showModalUser(false)
        showLoader(false)
    }

    const columns = [
        {
            Header: 'no',
            Footer: 'no',
            accessor: 'no',
        },
        {
            Header: () => <span className='p-4'>Code</span>,
            Footer: 'code_web',
            accessor: 'code_web',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: 'create_by_var',
            Footer: 'create_by_var',
            accessor: 'create_by_var',
        },
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({row}: any) => {
                const data = row.original
                if(parseInt(user?.role_id) === 99){
                    return (
                        <div className='text-xl scale-110 space-x-1 2xl:space-x-1.5'>
                            {/* <i className="ri-edit-box-line text-primary hover:text-green-400 cursor-pointer" onClick={() =>handleEditData(data)}></i> */}
                            
                            <i className="ri-delete-bin-2-line text-red-500 hover:text-red-400 cursor-pointer" onClick={()=>handleDeleteItem(data)}></i>
                        </div>
                    )
                }

                return <></>
            }
        }
    ]

    const handleSearch = (event: any) => {
        event.preventDefault()

        const newData = [...dataUser]
        if(event.target.value){
            const filtered = newData.filter((item: any) => {
                return (
                    item.fullname?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.username?.toLowerCase().includes(event.target.value.toLowerCase()) || 
                    item.email?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.role_name?.toLowerCase().includes(event.target.value.toLowerCase())
                )
            });

            setFilterData(filtered)
        }else{
            setFilterData(dataUser)
        }
    }

    return (
        <>
            <div className='flex item-center flex-col p-6'>
                <div className='w-full bg-white rounded-lg shadow-xl p-6'>
                    <div className='mb-5'>
                        <h1 className='font-bold'>Manage Web Survey</h1>
                    </div>

                    {/* TABLE */}
                    <div className='flex justify-between md:items-center flex-col md:flex-row space-y-2 md:space-y-0 mb-3'>
                        <button className='btn-primary' onClick={()=>showModalUser(true)}>Create New</button>
                        <SearchField placeholder='Search user...' onChange={handleSearch} />
                    </div>
                    <TableFull dataTable={filterData} columnTable={columns} />

                </div>
            </div>
            {modalUser && <CodeAddform data={selectedUser} onClose={resetForm} onSubmit={handleReceiveDataForm} />}
        </>
    );
}

export default Administrator_managecode;