import axios from "axios";

// export const baseURL = "http://localhost:2324"                          // {Local}
export const baseURL = "https://apisurvey.yotproject.or.id"                   // {Local}

// export const baseURL = "http://192.168.0.41:2323"                          // {Local}
// export const baseURL = "https://apitest.goes2nobel.com"                          // {Production}
//export const baseURL = "https://api.moljafurniture.com"                          // {Production}

export interface clucode_check {
    code_web: String
}

export interface list_dep {
    dept_id: String,
    dept_desc_var: String
}

export interface helpermenu {
    Title: String,
    message: String,
    textbutton: String
}

export interface RegisterPayload {
    code_web: String,
    username: String,
    password: String,
    fullname: String,
    dept_id: String,
    status_dept_var: String,
}

export interface LoginPayload {
    username: String,
    password: String
}

export const loginAPI = async (payload: LoginPayload) => {
    try {
        const data = await axios.post(`${baseURL}/api/sign-in`, payload)
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const Registerapi = async (payload: RegisterPayload) => {
    try {
        const data = await axios.post(`${baseURL}/api/sign-up`, payload)
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const Code_check = async (payload: clucode_check) => {
    try {
        const data = await axios.post(`${baseURL}/api/check_code`, payload)
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}
export const Get_list_departement = async () => {
    try {
        const data = await axios.post(`${baseURL}/api/dept-getall`)
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}
