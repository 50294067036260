import React, {  } from 'react';

interface Question {
  id: number;
  id_int: number;
  no: number;
  
  code_subbab: string;
  subbab_quiz: string;
  quiz_text: string;
  quiz_type_int: number;
  options: string[];
  isanswer: boolean;
  quiz_answer_option_code: number;
  answer_survey: string;
} 

interface QuestionDisplayProps {
  question: Question;
  onOptionClick: (optionIndex: number) => void;
  onTextboxChange: (value: string) => void;
  submit_text: () => void;
}

const buttonStyle = {
  backgroundColor:  'green',
  color: 'white',
  transition: 'background-color 0.1s', // Add a smooth transition effect
  width: '300px' ,
  marginTop : '30px',
  padding: '8px 16px',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: 'White',
  },
};
const QuestionDisplay: React.FC<QuestionDisplayProps> = ({ question, onOptionClick, onTextboxChange , submit_text}) => {
  console.log(question)
  return (
    <div className="question-display" >
      <h2>[{question.quiz_type_int}-{question.code_subbab}-{question.no}] {question.subbab_quiz}</h2>
      <h2 style={{ width: '50vw' }}>{question.quiz_text}</h2>
      
      {question.quiz_type_int === 1 ? (
          <ul>
            {question.options.map((option, index) => (
              <li
                key={index}
                onClick={() => onOptionClick(index)}
                className={question.quiz_answer_option_code === index ? 'bg-green-500 p-5 rounded-lg shadow-lg' : 'bg-white p-5 rounded-lg shadow-lg'}
              >
                {option}
              </li>
            ))}
          </ul>
        ) : (
          <div>
            <input
              type="text"
              onChange={(e) => onTextboxChange(e.target.value)}
              className="bg-white p-5 rounded-lg shadow-lg"
              style={{ width: '50vw' }}
            />
            <button onClick={submit_text} style={buttonStyle} >
                Simpan
            </button>
            
            <h2 style={{ width: '50vw', marginTop: '10px' }}>Jawaban Sebelumnya : {question.answer_survey}</h2>
          </div>
        )}
    </div>
  );
}


export default QuestionDisplay;