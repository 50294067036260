import axios from "axios"
import { baseURL } from "./common-api"

export interface payloadlistsurvey {
    code_web?: string,
}
export interface payloadreport {
    username?: string,
    code_web?: string,
    code_quiz?: string,
}
export interface UserPayload {
    id?: number
    username?: string,
    password?: string,
    fullname?: string,
    email?: string,
    role_id?: number
}

export interface helpermenu {
    Title?: String,
    message?: String,
    textbutton?: String
}
export interface datamasterSurvey {
    code_quiz?: string
    desc_quiz?: string,
    id_int?: string,
    longtime_quiz?: string,
    name_quiz?: string,
    question_count?: string,
    subbab_count?: string,
}

export interface enrollpayload_datamasterSurvey {
    username?: string,
    code_web?: string,
    code_quiz?: string,
}
export interface load_listbyuser {
    username?: string,
}

export interface enrollpayload_datasubmit {
    username?: string,
    code_web?: string,
    code_quiz?: string,
    
    code_subbab?: string,
    quiz_type_int?: number,
    no?: number,
    id_int?: number,
    answer_survey?: string,
    quiz_answer_option_code?: string,
    isAnswer?: string,
}

class survey_api 
{
    static report_survey = async (payload : payloadreport) => {
        try {
            const token = localStorage.getItem("authToken");
            const data = await axios.post(baseURL + '/api/quiz/report/generate_result',payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }
    static mastersurvey_list = async (payload : payloadlistsurvey) => {
        try {
            const token = localStorage.getItem("authToken");
            const data = await axios.post(baseURL + '/api/quiz/setup/master_1_findall',payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }
    static mastersurvey_listmaster_1_findbyuser = async (payload : load_listbyuser) => {
        try {
            const token = localStorage.getItem("authToken");
            const data = await axios.post(baseURL + '/api/quiz/setup/master_1_findbyuser',payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }

    
    static mastersurvey_enroll = async (payload : enrollpayload_datamasterSurvey) => {
        try {
            const token = localStorage.getItem("authToken");
            const data = await axios.post(baseURL + '/api/quiz/quiz_enroll', payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }
    static mastersurvey_recall = async (payload : enrollpayload_datamasterSurvey) => {
        try {
            const token = localStorage.getItem("authToken");
            const data = await axios.post(baseURL + '/api/quiz/quiz_recall', payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }
    static mastersurvey_submit = async (payload : enrollpayload_datasubmit  ) => {
        try {
            const token = localStorage.getItem("authToken");
            const data = await axios.post(baseURL + '/api/quiz/quiz_submit', payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }
}

export default survey_api
 