import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import TableFull from '../../components/Table';
import SurveyAPI, { datamasterSurvey, enrollpayload_datamasterSurvey, helpermenu, load_listbyuser} from '../../api/survey-api';

import Enrollform from '../../components/modals/EnrollForm'
import ConfirmForm from '../../components/modals/ConfirmForm';

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from '../../components/modals/ConfirmModal';
type Props = {};

const MainDashboard: React.FC<Props> = () => {
    const navigate = useNavigate()
    const userLocal = localStorage.getItem('dataUser')
    const user = userLocal? JSON.parse(userLocal || '') : null
    
    const [data_helpermenu, set_helpermenu] = useState<helpermenu>({})
    

    const [modal_showconfirm, showmodal_showconfirm] = useState(false)

    const [data_datamasterSurvey_selected, set_datamasterSurvey_selected] = useState<datamasterSurvey>({})
    const [data_datamasterSurvey, set_datamasterSurvey] = useState<datamasterSurvey>({})
    
    const [data_enrollpayloaddatamasterSurvey, setpayload_datamasterSurvey] = useState<enrollpayload_datamasterSurvey>({})
    const [data_load_listbyuser, setload_listbyuser] = useState<load_listbyuser>({})
    
    const [data_check_survey_filter, set_check_survey_filter] = useState([])
    const [data_check_survey, set_check_survey] = useState([])

    const [modal_enrollsurvey, showModal_enrollsurvey] = useState(false)

    const masterlist = useCallback( async () => {
        data_load_listbyuser.username = user.username;
        const res = await SurveyAPI.mastersurvey_listmaster_1_findbyuser(data_load_listbyuser);
        console.log('mastersurvey_list : ',res)
        // set_show_riwayat(false)
        if(res.data){
            if(res.data.code === 0){
                set_check_survey_filter(res.data.data);
                set_check_survey(res.data.data);
            } else if(res.data.code === 99){
                navigate("/auth/sign-up");
            }else{
                toast.error(res.data.message)
            }
        }
        else{
            toast.error(res.message)
        }
    }, [])

    useEffect(() => {
        masterlist()
    }, [masterlist]); 

    const handleReceiveDataForm_enroll = async (data: datamasterSurvey) => {
        showModal_enrollsurvey(false)
        console.log('handleReceiveDataForm_enroll :', data)
        data_enrollpayloaddatamasterSurvey.code_web = user.code_web
        data_enrollpayloaddatamasterSurvey.username = user.username
        data_enrollpayloaddatamasterSurvey.code_quiz = data.code_quiz

        const res = await SurveyAPI.mastersurvey_enroll(data_enrollpayloaddatamasterSurvey);
        console.log('mastersurvey_enroll : ',res)
        if(res.data){
            if(res.data.code === 0){
                
                const dataUser = {
                    id: user.role_id,
                    username: user.username,
                    code_web: user.code_web,
                    fullname: user.fullname,
                    email: user.email,
                    role_id: Number( user.role_id),
                    last_login_on: user.last_login_on,
                    code_quiz: data.code_quiz
                }

                localStorage.setItem('dataUser', JSON.stringify(dataUser))

                const menuData: helpermenu = {
                    Title : "TERIMAKASIH",
                    message : "anda sukses Enroll, Click OK untuk melanjutkan",
                    textbutton : "OK"
                };
                set_helpermenu(menuData);
                showmodal_showconfirm(true);
                // navigate('/quiz/quiz/');
            } else if(res.data.code === 99){
                navigate("/auth/sign-up");
            }else{
                toast.error(res.data.message)
            }
        }
        else{
            toast.error(res.message)
        }
    }
    const resetForm = () => {
        showmodal_showconfirm(false)
        showModal_enrollsurvey(false)
    }
    const onnextquiz = () => {
        showmodal_showconfirm(false)
        navigate('/quiz/quiz/');
    }
    const handle_ui_detailsurvey =  (selectedData: datamasterSurvey) => {
        console.log(selectedData)
        showModal_enrollsurvey(true)
        set_datamasterSurvey_selected(selectedData)
        // navigate('/quiz/quiz/');
    }
    
    const columns_survey= [
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({row}: any) => {
                const data = row.original
                return (
                    <div className='text-xl scale-110 space-x-1 2xl:space-x-1.5'>
                        {data.isenroll === 1 ? (
                            <span className="text-red-400 hover:text-red-400 cursor-pointer">
                                SELESAI
                            </span>
                        ) : (
                            <span className="text-primary hover:text-red-400 cursor-pointer" onClick={() => handle_ui_detailsurvey(data)}>
                                MULAI
                            </span>
                        )}
                    </div>
                );
    //             return (
    //                 <div className='text-xl scale-110 space-x-1 2xl:space-x-1.5'>
    //                     <span className="text-primary hover:text-red-400 cursor-pointer" onClick={() => handle_ui_riwayatIPL(data)}>
    //                         MULAI
    //                     </span>
    // {/* <i className="ri-edit-box-line text-primary hover:text-green-400 cursor-pointer" onClick={() =>handle_ui_riwayatIPL(data)}></i> */}
    //                     {/* <i className="ri-delete-bin-2-line text-red-500 hover:text-red-400 cursor-pointer" onClick={()=>handleDeleteItem(data)}></i> */}
    //                 </div>
    //             )

                return <></>
            }
        },
        {
            Header: () => <span className='p-4'>Nama Survey</span>,
            Footer: 'name_quiz',
            accessor: 'name_quiz',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Deskripsi Survey</span>,
            Footer: 'desc_quiz',
            accessor: 'desc_quiz',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Jumlah Subbab</span>,
            Footer: 'subbab_count',
            accessor: 'subbab_count',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Jumlah Soal</span>,
            Footer: 'question_count',
            accessor: 'question_count',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        }
    ]

    return (
        <div className='flex item-center flex-col p-5 md:p-6 mb-auto'>
            <h1 className='text-base font-semibold'>Dashboard</h1>

            <div className="border-2 border-dark rounded-lg mt-5">
                <div className="bg-dark p-5 text-white rounded-tl-md rounded-tr-md">
                    <p>Welcome Back! <span className="font-semibold">{user?.fullname || user?.username}</span></p>
                </div>
                <div className="bg-white px-5 py-6 rounded-bl-lg rounded-br-lg flex flex-col md:flex-row justify-between">
                    <p>You are logged in!</p>
                    <div className='mt-5 md:mt-0'>
                        <p>Login time:</p>
                        <p>{moment(user?.last_login_on).format('yyyy-MM-DD HH:mm:ss')}</p>
                    </div>
                </div>
            </div>

            <h1 className='text-base font-semibold mt-10'>List Survey</h1>
            <TableFull dataTable={data_check_survey_filter} columnTable={columns_survey} />
             
            {modal_showconfirm && <ConfirmModal message='TEST' onebutton={true} onClose={resetForm}  onNext={onnextquiz} />}
        
            {modal_enrollsurvey && <Enrollform data={data_datamasterSurvey_selected}  onClose={resetForm}  onSubmit={handleReceiveDataForm_enroll} />}
        </div>
    )
}

export default MainDashboard;