import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createNewUser, deleteUser, getAllUser, updateUser, UserPayload } from '../../api/user-api';
import UserForm from '../../components/modals/UserForm';
import SearchField from '../../components/SearchField';
import TableFull from '../../components/Table';
import SurveyAPI, { datamasterSurvey , payloadlistsurvey, payloadreport} from '../../api/survey-api';
import ResultSummary from '../../components/modals_report/ResultSummary';
import ResultSaran from '../../components/modals_report/ResultSaran';

import * as XLSX from 'xlsx'; // Import xlsx library

type Props = {};

const Report_survey: React.FC<Props> = () => {
    const [isHovered, setIsHovered]             = useState(false);
    const buttonStyle = {
      backgroundColor: isHovered ? 'grey' : 'green',
      color: 'white',
      transition: 'background-color 0.1s', // Add a smooth transition effect
      width: '300px' ,
      padding: '8px 16px',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      ':hover': {
        backgroundColor: 'White',
      },
    };
    const handleDownload_enroll = () => {
        // Create a new workbook
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(report_data_report_enroll_person);
      
        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Summary');
      
        // Convert workbook to binary XLSX and trigger download
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
      
        function s2ab(s: string) {
          const buf = new ArrayBuffer(s.length);
          const view = new Uint8Array(buf);
          for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
          return buf;
        }
      
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'summary.xlsx';
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      };
    const handleDownload_plan = () => {
        // Create a new workbook
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(report_data_result_plandata);
        
        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Summary');
        
        // Convert workbook to binary XLSX and trigger download
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
        
        function s2ab(s: string) {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
            return buf;
    }
    
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'summary.xlsx';
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }, 0);
    };
    const [modalReport, showmodalReport] = useState(false)
    const [report_data_report_enroll_person, report_set_report_enroll_person] = useState([])
    const [report_data_result_summary_optional, report_set_result_summary_optional] = useState([])
    const [report_data_result_summary_saran, report_set_result_summary_saran] = useState([])
    const [report_data_result_plandata, report_set_result_plandata] = useState([])

    const [data_check_survey_filter, set_check_survey_filter] = useState([])
    const [data_check_survey, set_check_survey] = useState([])

    const [loader, showLoader] = useState(false)
    const [modalUser, showModalUser] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const [dataUser, setDataUser] = useState([])
    const [filterData, setFilterData] = useState([])
    const [selectedUser, setSelectedUser] = useState<UserPayload>({})
    const [payloadlistsurvey, setpayloadlistsurvey] = useState<payloadlistsurvey>({})
    
  const [selectedData, setSelectedData] = useState<string>('');
    const [payloadpayloadreport, setpayloadreport] = useState<payloadreport>({})
    
    const userLocal = localStorage.getItem('dataUser')
    const user = userLocal? JSON.parse(userLocal || '') : null

    const navigate = useNavigate()

    const masterlist = useCallback( async () => {
        payloadlistsurvey.code_web = user.code_web;
        const res = await SurveyAPI.mastersurvey_list(payloadlistsurvey);
        // console.log('mastersurvey_list : ',res)
        if(res.data){
            if(res.data.code === 0){
                set_check_survey_filter(res.data.data);
                set_check_survey(res.data.data);
            } else if(res.data.code === 99){
                navigate("/auth/sign-up");
            }else{
                toast.error(res.data.message)
            }
        }
        else{
            toast.error(res.message)
        }
    }, [])

    const reportAPI = useCallback( async (selectedData: datamasterSurvey) => {
        showmodalReport(false)
        try {
            payloadpayloadreport.code_quiz = selectedData.code_quiz;
            payloadpayloadreport.username = user.username;
            payloadpayloadreport.code_web = user.code_web;
            const res = await SurveyAPI.report_survey(payloadpayloadreport);
            console.log('report_survey : ',res)
            if(res.data){
                if(res.data.code === 0){
                    if(res.data.data.report_enroll_person != null)
                    {
                        showmodalReport(true)
                        report_set_report_enroll_person(res.data.data.report_enroll_person)
                        report_set_result_summary_optional(res.data.data.result_summary_optional)
                        report_set_result_summary_saran(res.data.data.result_summary_saran)
                        report_set_result_plandata(res.data.data.result_data_plan)
                    }
                    else{
                        toast.error('Data Tidak Ditemukan')
                    }
                } else if(res.data.code === 99){
                    navigate("/auth/sign-up");
                }else{
                    toast.error(res.data.message)
                }
            }
            else{
                toast.error(res.message)
            }
          } catch (error) {
            // Handle the error
            toast.error(error)
            // You can also do other things like show a message to the user, set state, etc.
          }
        
    }, [])

    useEffect(() => {
        masterlist()
    }, [masterlist])


    const handle_ui_detail =  (selectedData: datamasterSurvey) => {
        // console.log(selectedData)
        reportAPI(selectedData);
    }
    const handleEditData =  (selectedData: UserPayload) => {
        setSelectedUser(selectedData)
        setIsUpdate(true)
        showModalUser(true)
    }

    const handleReceiveDataForm = async (data: UserPayload) => {
        showLoader(true)
        
        let res = null
        if(!isUpdate){
            res = await createNewUser(data)
        }else{
        }

        // console.log('Create/Update User :', res)
        
        if(res.data){
            if(res.data.code === 0){
                toast.success('Success')
                masterlist()
                resetForm()
            }else{
                toast.error(res.data.message)
                showLoader(false)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }

    const handleDeleteItem = async (data: UserPayload) => {
        // const res = await deleteUser(data.id || 1)

        // console.log("DELETE USER :", res)
        // if(res.data){
        //     if(res.data.code === 0){
        //         toast.success('Success')
        //         fetchUser()
        //     }else{
        //         toast.error(res.data.message)
        //     }
        // }else{
        //     alert(`${res.config?.url} ${res.message}`)
        // }
    }

    const resetForm = () => {
        setSelectedUser({})
        setIsUpdate(false)
        showModalUser(false)
        showLoader(false)
    }
    const columns_report_enroll= [
        {
            Header: () => <span className='p-4'>No</span>,
            Footer: 'no',
            accessor: 'no',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Username</span>,
            Footer: 'username',
            accessor: 'username',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>total soal</span>,
            Footer: 'total_soal',
            accessor: 'total_soal',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>terjawab</span>,
            Footer: 'terjawab',
            accessor: 'terjawab',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Progress (%)</span>,
            Footer: 'percentage_answered',
            accessor: 'percentage_answered',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        }
    ]
    const columns_report_optional= [
        {
            Header: () => <span className='p-4'>No</span>,
            Footer: 'no',
            accessor: 'no',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Nama Subbab</span>,
            Footer: 'namasubbab',
            accessor: 'namasubbab',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Soal</span>,
            Footer: 'soal',
            accessor: 'soal',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Total</span>,
            Footer: 'total',
            accessor: 'total',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        }
    ]
    const columns_report_saran= [
        {
            Header: () => <span className='p-4'>No</span>,
            Footer: 'no',
            accessor: 'no',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Username</span>,
            Footer: 'username',
            accessor: 'username',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Saran</span>,
            Footer: 'saran',
            accessor: 'saran',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        }
    ]
    const columns_report_dataplan= [
        {
            Header: () => <span className='p-4'>No</span>,
            Footer: 'no_data',
            accessor: 'no_data',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Username</span>,
            Footer: 'username',
            accessor: 'username',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Code Quiz</span>,
            Footer: 'code_quiz',
            accessor: 'code_quiz',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Type</span>,
            Footer: 'quiz_type_int',
            accessor: 'quiz_type_int',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Soal </span>,
            Footer: 'quiz_text',
            accessor: 'quiz_text',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Jawaban </span>,
            Footer: 'answer_survey',
            accessor: 'answer_survey',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        }
    ]
    
const columns_survey= [
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({row}: any) => {
                const data = row.original
                return (
                    <div className='text-xl scale-110 space-x-1 2xl:space-x-1.5'>
                        <span className="text-primary hover:text-red-400 cursor-pointer" onClick={() => handle_ui_detail(data)}>
                            DETAIL
                        </span>
                    </div>
                );
    //             return (
    //                 <div className='text-xl scale-110 space-x-1 2xl:space-x-1.5'>
    //                     <span className="text-primary hover:text-red-400 cursor-pointer" onClick={() => handle_ui_riwayatIPL(data)}>
    //                         MULAI
    //                     </span>
    // {/* <i className="ri-edit-box-line text-primary hover:text-green-400 cursor-pointer" onClick={() =>handle_ui_riwayatIPL(data)}></i> */}
    //                     {/* <i className="ri-delete-bin-2-line text-red-500 hover:text-red-400 cursor-pointer" onClick={()=>handleDeleteItem(data)}></i> */}
    //                 </div>
    //             )

                return <></>
            }
        },
        {
            Header: () => <span className='p-4'>Nama Survey</span>,
            Footer: 'name_quiz',
            accessor: 'name_quiz',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Deskripsi Survey</span>,
            Footer: 'desc_quiz',
            accessor: 'desc_quiz',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Jumlah Subbab</span>,
            Footer: 'subbab_count',
            accessor: 'subbab_count',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Jumlah Soal</span>,
            Footer: 'question_count',
            accessor: 'question_count',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Peserta Enroll</span>,
            Footer: 'jumlah_enroll',
            accessor: 'jumlah_enroll',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        }
    ]
    const handleSearch = (event: any) => {
        event.preventDefault()

        const newData = [...dataUser]
        if(event.target.value){
            const filtered = newData.filter((item: any) => {
                return (
                    item.fullname?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.username?.toLowerCase().includes(event.target.value.toLowerCase()) || 
                    item.email?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.role_name?.toLowerCase().includes(event.target.value.toLowerCase())
                )
            });

            setFilterData(filtered)
        }else{
            setFilterData(dataUser)
        }
    }

    return (
        <>
            <div className='flex item-center flex-col p-6'>
                <div className='w-full bg-white rounded-lg shadow-xl p-6 mt-10'>
                    <div className='mb-5'>
                        <h1 className='font-bold'>List Survey</h1>
                    </div>
                    <TableFull dataTable={data_check_survey_filter} columnTable={columns_survey} />
                </div>
            </div>
            {modalUser && <UserForm data={selectedUser} onClose={resetForm} onSubmit={handleReceiveDataForm} />}
            
           {modalReport &&
            <div>
                <div className='flex item-center flex-col p-6'>
                    <div className='w-full bg-white rounded-lg shadow-xl p-6 mt-10'>
                        <div className='mb-5'>
                            <h1 className='font-bold'>List Enroll Data</h1>
                        </div>
                        <TableFull dataTable={report_data_report_enroll_person} columnTable={columns_report_enroll} />
                        <button onClick={handleDownload_enroll} style={buttonStyle} 
                            onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} >
                            Download XLS
                        </button>
                    </div>
                </div>

                <div className='flex item-center flex-col p-6'>
                    <div className='w-full bg-white rounded-lg shadow-xl p-6 mt-10'>
                        <div className='mb-5'>
                            <h1 className='font-bold'>List Optional</h1>
                        </div>
                        <ResultSummary result_summary_optional={report_data_result_summary_optional} columnTable={columns_report_optional} />
                    </div>
                </div>

                <div className='flex item-center flex-col p-6'>
                    <div className='w-full bg-white rounded-lg shadow-xl p-6 mt-10'>
                        <div className='mb-5'>
                            <h1 className='font-bold'>List Saran</h1>
                        </div>

                        <ResultSaran result_summary_saran={report_data_result_summary_saran} columnTable={columns_report_saran} />
                   
                    </div>
                </div>

                <div className='flex item-center flex-col p-6'>
                    <div className='w-full bg-white rounded-lg shadow-xl p-6 mt-10'>
                        <div className='mb-5'>
                            <h1 className='font-bold'>List Data Plan</h1>
                        </div>
                        <TableFull dataTable={report_data_result_plandata} columnTable={columns_report_dataplan} />
                        <button onClick={handleDownload_plan} style={buttonStyle} 
                            onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} >
                            Download XLS
                        </button>
                    </div>
                </div>
            </div>
           }
            
        </>
    );
}

export default Report_survey;