import React, { useEffect, useState } from 'react';

interface QuestionNavigationProps {
  questions: { id: number; isanswer: boolean ; code_subbab: string }[];
  onQuestionChange: (index: number) => void;
}

const QuestionNavigation: React.FC<QuestionNavigationProps> = ({ questions, onQuestionChange }) => {
  const [isHorizontal, setIsHorizontal] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const containerWidth = document.querySelector('.navigation')?.clientWidth || 0;
      const buttonWidth = document.querySelector('.navigation button')?.clientWidth || 0;
      const buttonsPerRow = Math.floor(containerWidth / buttonWidth);
      setIsHorizontal(buttonsPerRow >= questions.length);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [questions]);

  // console.log(questions)
  return (
    //${isHorizontal ? 'horizontal' : 
    <div className={`navigation vertical'}`} style={{ maxHeight: '80vh', overflowY: 'auto' }}>
      {questions.map((question, index) => (
        <button
          key={question.id}
          onClick={() => onQuestionChange(index)}
          className={question.isanswer ? 'bg-green-500 rounded-lg shadow-lg mt-5' : 'bg-white rounded-lg shadow-lg  mt-5'}
        >
          {question.id}
        </button>
      ))}
    </div>
  );
}

export default QuestionNavigation;