import React, { useCallback, useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QuestionNavigation from './QuestionNavigation';
import QuestionDisplay from './QuestionDisplay';
import SurveyAPI, { datamasterSurvey, enrollpayload_datamasterSurvey, enrollpayload_datasubmit} from '../../api/survey-api';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import ConfirmModal from '../../components/modals/ConfirmModal';

// interface Question {
//   id: number;
//   subbab: string;
//   text: string;
//   options: string[];
//   isAnswer: boolean;
//   optionChoice: number;
// } 

// const initialQuestions: Question[] = [
//   { id: 1, subbab: 'Survey Sepatu', text: 'Sepatu Biru 1', options: ['Sangat Setuju', 'Setuju', 'Tidak Setuju', 'Sangat Tidak Setuju'], isAnswer: false, optionChoice: -1 },
//   { id: 2, subbab: 'Survey Sepatu', text: 'Sepatu Biru 2', options: ['Sangat Setuju', 'Setuju', 'Tidak Setuju', 'Sangat Tidak Setuju'], isAnswer: false, optionChoice: -1 },
//   // Add more initial questions if needed
// ];

interface Question {
  id: number;
  id_int: number;
  no: number;
  code_subbab: string;
  subbab_quiz: string;
  quiz_text: string;
  answer_survey: string;
  options: string[];
  isanswer: boolean;
  quiz_answer_option_code: number;
  quiz_type_int: number;
} 
interface summary {
  total_soal: string;
  terjawab: string;
  percentage_answered: string;
} 

const initialQuestions: Question[] = [
  { id: 1, no: 0, id_int: 0,quiz_type_int:0, code_subbab: '', answer_survey: '', quiz_text: '', options: [], isanswer: false, quiz_answer_option_code: -1,subbab_quiz:'' },
  { id: 2, no: 0, id_int: 0,quiz_type_int:0,  code_subbab: '', answer_survey: '', quiz_text: '', options: [], isanswer: false, quiz_answer_option_code: -1,subbab_quiz:'' }, // // Add more initial questions if needed
];
const App: React.FC = () => {
  const navigate = useNavigate()
  const [modal_showconfirm, showmodal_showconfirm] = useState(false)

  const [questions, setQuestions] = useState<Question[]>(initialQuestions);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [countindex, setcountindex] = useState<number>(1);
  const [max_index, det_maxindex] = useState<number>(0);
  const userLocal = localStorage.getItem('dataUser')
  const user = userLocal? JSON.parse(userLocal || '') : null

  const [data_inputtext,set_inputtext] = useState('')
  const [data_summary, setsummary] = useState<summary>()
  const [data_enrollpayloaddatamasterSurvey, setpayload_datamasterSurvey] = useState<enrollpayload_datamasterSurvey>({})
  const [data_enrollpayload_datasubmit, setpayload_enrollpayload_datasubmit] = useState<enrollpayload_datasubmit>({})

  const handleQuestionChange = (index: number) => {
    setCurrentQuestionIndex(index);
  };
  const masterlist = useCallback( async () => 
  {
    if(user == null)
    {
      showmodal_showconfirm(true)
      //  navigate('/dashboard', {replace: true})
       return;
    }
    if(user.code_web == null)
    {
      showmodal_showconfirm(true)
      //  navigate('/dashboard', {replace: true})
       return;
    }
    data_enrollpayloaddatamasterSurvey.code_web = user.code_web
    data_enrollpayloaddatamasterSurvey.username = user.username
    data_enrollpayloaddatamasterSurvey.code_quiz = user.code_quiz
  
    // console.log('data_enrollpayloaddatamasterSurvey : ',data_enrollpayloaddatamasterSurvey)
    const res = await SurveyAPI.mastersurvey_recall(data_enrollpayloaddatamasterSurvey);
    // console.log('mastersurvey_enroll : ',res)
    if(res.data)
    {
        if(res.data.code === 0)
        {
          setcountindex(1) 
          setsummary(res.data.data.progress)
          setQuestions(res.data.data.soal)
          det_maxindex(res.data.data.soal.length)
        } else if(res.data.code === 99){
            navigate("/auth/sign-up");
        }else{
            toast.error(res.data.message)
            navigate("/");
        }
    }
    else{
        toast.error(res.message)
        navigate("/");
    }
}, [])

useEffect(() => {
  masterlist()
}, [masterlist]); // Run once on component mount

const resetForm = () => {
  showmodal_showconfirm(false)
}
const onnextquiz = () => {
  showmodal_showconfirm(false)
  navigate('/dashboard', {replace: true})
}
  const handleOptionClick = async (optionIndex: number) => {
    const updatedQuestions = [...questions];
    
    
    console.log('handleOptionClick ',optionIndex, max_index,'/',currentQuestionIndex,'[',countindex,'] ', 
      user.username, user.code_web,  user.code_quiz, 
       updatedQuestions[currentQuestionIndex].options[optionIndex], 
       updatedQuestions[currentQuestionIndex].quiz_text,
        updatedQuestions[currentQuestionIndex].id)
    
    data_enrollpayload_datasubmit.code_web      = user.code_web
    data_enrollpayload_datasubmit.username      = user.username
    data_enrollpayload_datasubmit.code_quiz     = user.code_quiz
    
    data_enrollpayload_datasubmit.quiz_answer_option_code = String(optionIndex)
    data_enrollpayload_datasubmit.code_subbab   = updatedQuestions[currentQuestionIndex].code_subbab
    data_enrollpayload_datasubmit.quiz_type_int = updatedQuestions[currentQuestionIndex].quiz_type_int
    data_enrollpayload_datasubmit.quiz_type_int = updatedQuestions[currentQuestionIndex].quiz_type_int
    data_enrollpayload_datasubmit.no            = updatedQuestions[currentQuestionIndex].no
    data_enrollpayload_datasubmit.id_int        = updatedQuestions[currentQuestionIndex].id_int
    data_enrollpayload_datasubmit.answer_survey = updatedQuestions[currentQuestionIndex].options[optionIndex]
    data_enrollpayload_datasubmit.isAnswer      = 'true'
  
    console.log('data_enrollpayload_datasubmit : ',data_enrollpayload_datasubmit)
    const res = await SurveyAPI.mastersurvey_submit(data_enrollpayload_datasubmit);
    console.log('mastersurvey_submit : ',res)
    if(res.data)
    {
        if(res.data.code == 0)
        {
          setsummary(res.data.data.progress)
        } else if(res.data.code == 99){
          navigate("/auth/sign-up");
        }
        else if(res.data.code == 97){
          showmodal_showconfirm(true)
        }else{
            toast.error(res.data.message)
            // navigate("/");
        }
    }
    else{
        toast.error(res.message)
    }

    updatedQuestions[currentQuestionIndex] = {
      ...updatedQuestions[currentQuestionIndex],
      isanswer: true,
      quiz_answer_option_code: optionIndex,
    };
    setQuestions(updatedQuestions);
    // toast(`You selected: ${updatedQuestions[currentQuestionIndex].options[optionIndex]}`);
    console.log(optionIndex, max_index,'/',currentQuestionIndex,'[',countindex,'] ', user.username, user.code_web,  user.code_quiz,  updatedQuestions[currentQuestionIndex].options[optionIndex], updatedQuestions[currentQuestionIndex].quiz_text, updatedQuestions[currentQuestionIndex].id)
    

    if(currentQuestionIndex +1 < max_index)
    {
      setcountindex(countindex  + 1) 
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
    else
    {
      if(countindex ==max_index)
      {
        toast(`Selesai, Terimakasih`);
        navigate('/dashboard', {replace: true})
      }
      else
      {
        toast(`Ada yang terlewat, Mohon Check ulang`);
      }
    }
  };

  const handlesubmittext = async () => {
   
    const updatedQuestions = [...questions];
    updatedQuestions[currentQuestionIndex].answer_survey = data_inputtext;
    updatedQuestions[currentQuestionIndex].isanswer = true;
    updatedQuestions[currentQuestionIndex].quiz_answer_option_code =  0;
    data_enrollpayload_datasubmit.code_web      = user.code_web
    data_enrollpayload_datasubmit.username      = user.username
    data_enrollpayload_datasubmit.code_quiz     = user.code_quiz
    data_enrollpayload_datasubmit.quiz_answer_option_code     = '0'
    
    data_enrollpayload_datasubmit.quiz_answer_option_code = ''
    data_enrollpayload_datasubmit.code_subbab   = updatedQuestions[currentQuestionIndex].code_subbab
    data_enrollpayload_datasubmit.quiz_type_int = updatedQuestions[currentQuestionIndex].quiz_type_int
    data_enrollpayload_datasubmit.quiz_type_int = updatedQuestions[currentQuestionIndex].quiz_type_int
    data_enrollpayload_datasubmit.no            = updatedQuestions[currentQuestionIndex].no
    data_enrollpayload_datasubmit.id_int        = updatedQuestions[currentQuestionIndex].id_int
    data_enrollpayload_datasubmit.answer_survey = updatedQuestions[currentQuestionIndex].answer_survey
    data_enrollpayload_datasubmit.quiz_answer_option_code = '0'
    data_enrollpayload_datasubmit.isAnswer      = String(updatedQuestions[currentQuestionIndex].isanswer)
  
    console.log('data_enrollpayload_datasubmit : ',data_enrollpayload_datasubmit)
    const res = await SurveyAPI.mastersurvey_submit(data_enrollpayload_datasubmit);
    console.log('mastersurvey_submit : ',res)
    if(res.data)
    {
        if(res.data.code == 0)
        {
          setsummary(res.data.data.progress)
        } else if(res.data.code == 99){
          navigate("/auth/sign-up");
        }
        else if(res.data.code == 97){
          showmodal_showconfirm(true)
        }else{
            toast.error(res.data.message)
            // navigate("/");
        }
    }
    else{
        toast.error(res.message)
    }

    // if(currentQuestionIndex +1 < max_index)
    // {
    //   setcountindex(countindex  + 1) 
    //   setCurrentQuestionIndex(currentQuestionIndex + 1);
    // }
    // else
    // {
    //   if(countindex ==max_index)
    //   {
    //     toast(`Selesai, Terimakasih`);
    //     navigate('/dashboard', {replace: true})
    //   }
    //   else
    //   {
    //     toast(`Ada yang terlewat, Mohon Check ulang`);
    //   }
    // }
  };

  const handleTextboxChange = (value: string) => {
    // console.log(value)
    set_inputtext(value)
    // questions[currentQuestionIndex].answer_survey = value
  };

  return (
    
      <div className="App p-2">
        <QuestionNavigation
          questions={questions}
          onQuestionChange={handleQuestionChange}
        />
        <div className='flex item-center flex-col p-5 md:p-6 mb-auto'>
          <div className="border-2 border-dark rounded-lg mt-5">
              <div className="bg-dark p-5 text-white rounded-tl-md rounded-tr-md">
                <p>Summary : <span className="font-semibold">{user?.fullname || user?.username}</span></p>
                <p>Total Soal :  {data_summary?.total_soal} | Terjawab :  {data_summary?.terjawab} ({data_summary?.percentage_answered}%) </p>
              </div>
          </div>
          <QuestionDisplay
            question={questions[currentQuestionIndex]}
            onOptionClick={handleOptionClick}
            onTextboxChange={handleTextboxChange}
            submit_text={handlesubmittext}
          />
          
        </div>
        {modal_showconfirm && <ConfirmModal message='Sukses, Terimakasih sudah melakukan survey' onebutton={true} onClose={resetForm}  onNext={onnextquiz} />}
        
      </div>
  );
}
export default App;