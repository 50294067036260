import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import ErrorField from '../ErrorField';
// import 'react-datetime/css/react-datetime.css'
import moment, { Moment } from 'moment';
import { code_payload } from '../../api/user-api';

interface UserFormExtend extends code_payload {
    code_web: string,
}

type Props = {
    data?: any,
    onSubmit: (data: code_payload) => void,
    onClose: (e: MouseEvent<HTMLElement>) => void
};

const CodeAddform: React.FC<Props> = ({ data, onSubmit, onClose }) => {
        const {
            register,
            formState: { errors },
            handleSubmit,
            setValue
        } = useForm<any>({ criteriaMode: "all" });

        const [selectedDate_str, setSelectedDate_str] = useState('');
        const [selectedDate, setSelectedDate] = useState(new Date());

        const handleDateChanges = (value: string | Moment) => {
            if (typeof value === 'string') {
                setSelectedDate(new Date(value));
            } else {
                setSelectedDate(value.toDate());
                setSelectedDate_str(value.format('YYYY-MM-DD'));
            }
            // console.log("Date [Str] "+ selectedDate_str + " [Date] " + selectedDate)
        };
        const setdefaultValue = useCallback ((obj: any) => {
            Object.keys(obj).map(key => {
                return setValue(key, obj[key], { shouldValidate: true })
            })
        }, [setValue])

        useEffect(() => {
            // console.log(data)
            if(Object.keys(data).length !== 0){
                setdefaultValue(data)
            }
        }, [data, setdefaultValue])
    
        
        const onSubmitForm: SubmitHandler<UserFormExtend> = ({code_web}) => {
            const payload = {
                code_web
            } as code_payload

            onSubmit(payload)
        }
    
        return <>
            <div className="modal-form">
                <div className="modal-form-outside" onClick={onClose} />
                {/* <!-- Modal content --> */}
                <div className="w-11/12 md:w-3/5 rounded-lg shadow bg-gray-700 z-50 overflow-y-auto" style={{ maxHeight: '90vh' }}>
                    <div className="flex justify-end p-2">
                        <button type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-800 hover:text-white"
                            onClick={onClose}
                        >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>
    
                    <form onSubmit={handleSubmit(onSubmitForm)} className="px-6 pb-4 space-y-5 lg:px-8 sm:pb-6 xl:pb-8">
                        <div className="flex justify-center">
                            <h3 className="text-xl font-medium text-white pb-4">Tambah Code Web</h3>
                        </div>
                        <div className="w-full">
                            <label htmlFor="fullname" className="label-form">Code Web</label>
                            <input type="text" id="code_web" className="input-form" placeholder="Code Web" 
                                {...register('code_web', {
                                    required: 'code_web is required'
                                })}
                            />
                            <ErrorField errors={errors} name='code_web' />
                        </div>
                        
                        <div className="flex items-center pt-4 space-x-4">
                            <button type='submit' className="btn-primary px-7">Start Survey</button>
                            <button type='reset' className="btn-secondary" onClick={onClose}>Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
}

export default CodeAddform;