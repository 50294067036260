import axios from "axios"
import { baseURL } from "./common-api"


export interface code_payload {
    username?: string,
    code_web?: string,
}
export interface dept_list {
    no?: string,
    dept_id?: string,
    dept_desc_var?: string,
}
export interface role_list {
    no?: string,
    role_id?: string,
    role_desc_var?: string,
}
export interface dept_delete {
    username?: string,
    dept_id?: string,
    dept_desc_var?: string,
}
//=======================================================

export interface UserPayload {
    id?: number
    username?: string,
    password?: string,
    fullname?: string,
    email?: string,
    role_id?: number
}
export interface UserEditPayload {
    id?: number
    username?: string,
    password?: string,
    fullname?: string,
    email?: string,
    role_id?: number,
    dept_id?: number,
    status_dept_var?: string
}
export interface UserEditHITPayload {
    username?: string,
    target_username?: string,
    target_password?: string,
    target_fullname?: string,
    target_role_id?: string,
    target_dept_id?: string,
    target_status_dept_var?: string
}

export interface UserRemoveHITPayload {
    username?: string,
    target_username?: string
}

export const getOneUser = async (userID: number) => {
    try {
        const data = await axios.get(`${baseURL}/api/user-one?id=${userID}`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}


export const getAllUser = async () => {
    try {
        const data = await axios.post(baseURL + '/api/user-getall',{}, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const createNewUser = async (payload: UserPayload) => {
    try {
        const data = await axios.post(baseURL + '/api/user-new', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const updateUser = async (payload: UserEditHITPayload) => {
    try {
        const data = await axios.post(baseURL + '/api/sign-edit' ,payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}
export const deleteUser = async (payload: UserEditHITPayload) => {
    try {
        const data = await axios.post(baseURL + '/api/sign-del' ,payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}
export const role_getAll = async () => {
    try {
        const data = await axios.post(baseURL + '/api/role-getall', {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}
export const dept_getAll = async () => {
    try {
        const data = await axios.post(baseURL + '/api/dept-getall', {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}
export const dept_delete = async (payload: dept_delete) => {
    try {
        const data = await axios.post(baseURL + '/api/dept-del', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}
export const dept_add = async (payload: dept_delete) => {
    try {
        const data = await axios.post(baseURL + '/api/dept-add', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}
export const code_getAll = async () => {
    try {
        const data = await axios.post(baseURL + '/api/code-getall', {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}
export const code_delete = async (payload: dept_delete) => {
    try {
        const data = await axios.post(baseURL + '/api/code-del', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}
export const code_add = async (payload: dept_delete) => {
    try {
        const data = await axios.post(baseURL + '/api/code-add', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}